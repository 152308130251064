h1[data-lesson-title] {
  @apply pb-5 pt-3 text-3xl font-bold sm:text-4xl xl:text-[2.65rem] 2xl:text-4xl;
}

[data-lesson-badge] {
  @apply inline-block rounded-full px-2.5 py-1 font-mono text-xs font-semibold uppercase sm:mt-5 lg:text-sm 2xl:mt-0 2xl:text-xs;
}
[data-lesson-badge='solution'] {
  @apply bg-cyan-500/20 text-cyan-300;
}
[data-lesson-badge='exercise'] {
  @apply bg-orange-500/20 text-orange-300;
}
[data-lesson-badge='explainer'] {
  @apply bg-indigo-500/20 text-indigo-200;
}
[data-lesson-badge='interview'] {
  @apply bg-sky-500/20 text-sky-200;
}
