[data-login-template] {
  [data-verification-error] {
    @apply max-w-sm pt-4 text-center sm:mx-auto sm:w-full sm:pt-8;
  }
  [data-form] {
    @apply w-full sm:mx-auto sm:max-w-sm sm:pt-3 sm:text-lg;

    [data-input-container] {
      @apply relative mt-1 rounded-md shadow-sm;
      [data-icon] {
        @apply pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600;
        svg {
          @apply h-5 w-5;
        }
      }
      [data-input] {
        @apply mb-3 block w-full rounded-md border border-gray-600 bg-input py-7 pl-10 text-base text-white placeholder-gray-400 focus:border-cyan-300 focus-visible:ring-offset-0;
      }
    }
    [data-button] {
      @apply mt-3 flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-cyan-400 to-cyan-500 px-5 py-7 text-base font-semibold text-black shadow-xl shadow-black/20 transition hover:brightness-110 focus:outline-none focus:ring-2 focus:ring-cyan-100;
    }
  }
  [data-separator] {
    @apply py-5 text-center text-sm opacity-60;
  }
  [data-providers-container] {
    @apply mx-auto w-full space-y-2 sm:max-w-sm;
    [data-button] {
      @apply relative flex w-full items-center justify-center rounded-md border border-border bg-card px-5 py-7 text-base font-semibold text-white shadow-xl shadow-black/20 transition before:absolute before:-top-px before:left-0 before:h-px before:w-full before:bg-gradient-to-r before:from-transparent before:via-primary/50 before:to-transparent before:content-[""] hover:brightness-110 focus:outline-none focus:ring-2 focus:ring-cyan-100;
    }
  }
}

[data-check-your-email] {
  @apply relative flex flex-grow flex-col items-center justify-center px-5 pb-16 pt-5;
  [data-container] {
    @apply mx-auto flex w-full max-w-md flex-col items-center text-center;
  }
  [data-title] {
    @apply pb-5 text-center font-text text-3xl font-extrabold leading-9 sm:text-4xl lg:text-5xl;
  }
  [data-message] {
    @apply max-w-sm text-lg text-gray-300;
  }
}
