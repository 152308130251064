[cmdk-root] {
  @apply fixed left-1/2 top-1/2 z-50 w-full max-w-2xl -translate-x-1/2 -translate-y-1/2 border border-white/20 bg-black/80 shadow-2xl shadow-black/80 backdrop-blur-md;
  padding: 8px;
  border-radius: 12px;
  overflow: hidden;
  transition: transform 100ms ease;
}

[cmdk-input] {
  @apply w-full rounded-md bg-transparent py-3 pl-10 pr-5 text-white !ring-0 placeholder:text-gray-400;
}

[cmdk-loading] {
  height: 400px;
  @apply flex w-full flex-grow;
  div {
    @apply flex w-full flex-grow;
  }
  [cmdk-item] {
    @apply !bg-transparent text-base;
  }
}

[cmdk-item] {
  @apply flex items-center justify-between;
  content-visibility: auto;
  cursor: pointer;
  height: 48px;
  border-radius: 8px;
  font-size: 14px;
  gap: 8px;
  padding: 0 16px;
  color: var(--gray11);
  user-select: none;
  will-change: background, color;
  transition: all 150ms ease;
  transition-property: none;

  &[data-selected='true'] {
    @apply bg-white/10;
    color: var(--gray12);
  }

  &[data-disabled='true'] {
    color: var(--gray8);
    cursor: not-allowed;
  }

  &:active {
    transition-property: background;
    background: var(--gray4);
  }

  & + [cmdk-item] {
    margin-top: 4px;
  }
}

[cmdk-list] {
  height: min(330px, calc(var(--cmdk-list-height)));
  max-height: 400px;
  overflow: auto;
  overscroll-behavior: contain;
  transition: 100ms ease;
  transition-property: height;
  @apply h-full border-t border-white/5 pb-5 pr-2 pt-2 scrollbar-thin scrollbar-thumb-white/10;
}

[cmdk-vercel-shortcuts] {
  display: flex;
  margin-left: auto;
  gap: 8px;

  kbd {
    font-size: 12px;
    min-width: 20px;
    padding: 4px;
    height: 20px;
    border-radius: 4px;
    color: var(--gray11);
    background: var(--gray4);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }
}

[cmdk-separator] {
  height: 1px;
  width: 100%;
  background: var(--gray5);
  margin: 4px 0;
}

*:not([hidden]) + [cmdk-group] {
  margin-top: 8px;
}

[cmdk-group-heading] {
  user-select: none;
  font-size: 12px;
  color: var(--gray11);
  padding: 0 8px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

[cmdk-empty] {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  white-space: pre-wrap;
  color: var(--gray11);
}

--app-bg: var(--gray1);
--cmdk-shadow: 0 16px 70px rgb(0 0 0 / 20%);

--lowContrast: #000000;
--highContrast: #ffffff;

--gray1: hsl(0, 0%, 8.5%);
--gray2: hsl(0, 0%, 11%);
--gray3: hsl(0, 0%, 13.6%);
--gray4: hsl(0, 0%, 15.8%);
--gray5: hsl(0, 0%, 17.9%);
--gray6: hsl(0, 0%, 20.5%);
--gray7: hsl(0, 0%, 24.3%);
--gray8: hsl(0, 0%, 31.2%);
--gray9: hsl(0, 0%, 43.9%);
--gray10: hsl(0, 0%, 49.4%);
--gray11: hsl(0, 0%, 62.8%);
--gray12: hsl(0, 0%, 93%);

--grayA1: hsla(0, 0%, 100%, 0);
--grayA2: hsla(0, 0%, 100%, 0.026);
--grayA3: hsla(0, 0%, 100%, 0.056);
--grayA4: hsla(0, 0%, 100%, 0.077);
--grayA5: hsla(0, 0%, 100%, 0.103);
--grayA6: hsla(0, 0%, 100%, 0.129);
--grayA7: hsla(0, 0%, 100%, 0.172);
--grayA8: hsla(0, 0%, 100%, 0.249);
--grayA9: hsla(0, 0%, 100%, 0.386);
--grayA10: hsla(0, 0%, 100%, 0.446);
--grayA11: hsla(0, 0%, 100%, 0.592);
--grayA12: hsla(0, 0%, 100%, 0.923);

--blue1: hsl(212, 35%, 9.2%);
--blue2: hsl(216, 50%, 11.8%);
--blue3: hsl(214, 59.4%, 15.3%);
--blue4: hsl(214, 65.8%, 17.9%);
--blue5: hsl(213, 71.2%, 20.2%);
--blue6: hsl(212, 77.4%, 23.1%);
--blue7: hsl(211, 85.1%, 27.4%);
--blue8: hsl(211, 89.7%, 34.1%);
--blue9: hsl(206, 100%, 50%);
--blue10: hsl(209, 100%, 60.6%);
--blue11: hsl(210, 100%, 66.1%);
--blue12: hsl(206, 98%, 95.8%);
