#purchase-transfer {
  [data-transfer-state] {
    @apply flex flex-col  gap-3 rounded-lg border bg-card p-5 shadow-xl shadow-black/10;
    p {
      @apply font-normal text-gray-100;
    }
    button {
      @apply rounded-md bg-cyan-300/20 py-2.5 text-sm font-semibold text-cyan-300 shadow-none hover:bg-cyan-300/30;
    }
    input {
      @apply border border-gray-600/50 bg-gray-800 placeholder:text-gray-400;
    }
  }
  [data-transfer-state='AVAILABLE'] {
    h2 {
      @apply hidden;
    }
    p:last-of-type {
      @apply font-semibold;
    }
  }
  [data-transfer-state='INITIATED'] {
    h2 {
      @apply text-base font-normal text-gray-100 before:mr-1 before:animate-pulse before:rounded-full before:px-1 before:text-xl before:content-["📧"];
    }
    button {
      @apply self-start bg-rose-300/20 text-rose-200 hover:bg-rose-300/30;
    }
  }
  [data-transfer-state='COMPLETED'] {
    p:first-of-type {
      @apply before:mr-1 before:px-1 before:text-xl before:leading-none before:text-emerald-600 before:content-["✓"];
    }
  }
}

#purchase-detail {
  [data-transfer-state] {
    @apply flex flex-col gap-3 rounded-lg border-none bg-transparent p-0 shadow-none;
  }
}

/* Route: /products/[slug] */

[data-product-page] {
  #purchase-transfer {
    [data-transfer-state] {
      @apply border-transparent bg-transparent p-0;
      p {
        @apply font-normal text-gray-300;
      }
    }
  }
}
