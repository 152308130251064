[data-video-transcript] {
  @apply mx-auto max-w-4xl p-5 py-16;
  [data-title] {
    @apply flex items-baseline text-2xl font-semibold sm:text-3xl;
  }
  [data-transcript] {
    @apply prose max-w-none pt-4 sm:prose-lg prose-p:text-gray-300;
    [data-timestamp] {
      @apply inline-block underline after:inline-block after:content-['_'];
    }
  }
}

#tip {
  [data-video-transcript] {
    @apply mx-auto p-0 py-0;
    [data-title] {
      @apply flex items-baseline text-2xl font-semibold sm:text-3xl;
    }
    [data-transcript] {
      @apply prose max-w-none pt-4 sm:prose-lg prose-p:text-gray-300;
      [data-timestamp] {
        @apply inline-block underline after:inline-block after:content-['_'];
      }
    }
  }
}
