#ask {
  /* ——— QUIZ ——— */

  @apply flex h-full flex-grow items-center justify-center px-5 py-24;

  [data-sr-quiz='answered'] {
    [data-sr-quiz-question-body] {
      @apply hidden;
    }
    [data-sr-quiz-question-header] {
      @apply hidden;
    }

    [data-sr-quiz-question-footer] {
      @apply flex h-96 items-center justify-center;
    }
  }
  [data-sr-quiz-question-footer] {
    @apply hidden;
  }

  /* ——— QUESTION ——— */
  [data-sr-quiz-question] {
    @apply mx-auto w-full max-w-screen-sm;

    /* ——— HEADER ——— */
    [data-sr-quiz-question-header] {
      @apply prose prose-lg w-full max-w-none pb-6 font-medium leading-normal sm:prose-xl lg:prose-2xl prose-p:first-of-type:my-0;

      pre {
        @apply p-0;
      }
    }

    /* ——— BODY ——— */
    [data-sr-quiz-question-body] {
      @apply list-none;
      & > li {
        @apply list-none;
      }
    }
    [data-sr-quiz-question-error] {
      @apply text-gray-300;
    }
    /* ——— CHOICES ——— */
    [data-sr-quiz-question-choices] {
      @apply flex flex-col space-y-3;
    }

    /* ——— CHOICE ——— */
    [data-sr-quiz-question-choice] {
      img {
        @apply w-full;
      }
      label {
        @apply flex flex-wrap items-center rounded-md border-2 border-black border-opacity-10 p-5 transition-all duration-100 ease-in-out;
      }
      input {
        @apply mr-2;
      }
      p {
        @apply flex-grow leading-tight;
      }
      span {
        @apply rounded-full px-2 py-1 text-xs leading-none;
      }
    }

    [data-sr-quiz-question-choice=''] {
      input {
        @apply accent-cyan-500;
      }
      label {
        @apply cursor-pointer border-0 bg-gradient-to-r from-slate-800 transition hover:brightness-125 sm:text-lg;
      }
    }

    [data-sr-quiz-question-choice='correct'] {
      label {
        @apply border-green-500 border-opacity-40;
      }
      span {
        @apply bg-green-500 text-white;
      }
    }

    [data-sr-quiz-question-choice='incorrect'] {
      label {
        @apply cursor-default border-red-500 border-opacity-40;
      }
      span {
        @apply bg-red-500 text-white;
      }
    }

    /* ——— TEXTAREA ——— */
    [data-sr-quiz-question-input] {
      label {
        @apply sr-only inline-block pb-2 text-lg text-gray-300;
      }
      textarea {
        @apply w-full rounded-lg border border-gray-800 bg-black/40 p-3;
      }
    }

    /* ——— ANSWER ——— */
    [data-sr-quiz-question-answer] {
      @apply prose max-w-none pt-5;
      pre {
        @apply mb-5 p-0;
      }
    }

    /* ——— FOOTER ——— */
    [data-sr-quiz-question-footer] {
      @apply mx-auto flex w-full max-w-sm items-center justify-center text-center font-text text-5xl font-semibold drop-shadow-lg;
    }

    /* —— SUBMIT —— */
    [data-sr-button] {
      @apply mt-5 inline-flex cursor-pointer items-center rounded-md border-none bg-cyan-400 px-5 py-3 text-lg font-semibold leading-6 text-gray-900 transition hover:bg-cyan-300;
      svg {
        @apply h-6 w-6;
      }
    }

    [data-sr-button][disabled] {
      @apply cursor-not-allowed;
    }
  }
}

/* POPUP */

#popup {
  /* ——— QUIZ ——— */
  [data-sr-quiz] {
    @apply flex w-full flex-col items-center justify-center;
  }

  [data-sr-quiz='answered'] {
    [data-sr-quiz-question-body] {
      /*  @apply hidden; */
    }
    [data-sr-quiz-question-header] {
      /*  @apply hidden; */
    }
  }

  /* ——— QUESTION ——— */
  [data-sr-quiz-question] {
    @apply mx-auto w-full max-w-screen-sm;

    /* ——— HEADER ——— */
    [data-sr-quiz-question-header] {
      @apply prose prose-lg w-full max-w-none border-b border-gray-700/50 p-4 font-medium leading-normal transition-all prose-p:first-of-type:my-0;

      pre {
        @apply p-0;
      }
    }

    /* ——— BODY ——— */
    [data-sr-quiz-question-body] {
      @apply w-full list-none transition-all;
      & > li {
        @apply list-none;
      }
    }
    [data-sr-quiz-question-error] {
      @apply bg-white/5 px-3 py-2 text-sm text-gray-300;
    }
    /* ——— CHOICES ——— */
    [data-sr-quiz-question-choices] {
      @apply flex flex-col divide-y divide-gray-700/50;
    }

    /* ——— CHOICE ——— */
    [data-sr-quiz-question-choice] {
      img {
        @apply w-full;
      }
      label {
        @apply flex flex-wrap items-center px-4 py-3 transition-all duration-100 ease-in-out hover:bg-gray-900/50;
      }
      input {
        @apply mr-2;
      }
      p {
        @apply flex-grow leading-tight;
      }
      span {
        @apply rounded-full px-2 py-1 text-xs leading-none;
      }
    }

    [data-sr-quiz-question-choice] {
      label {
        @apply bg-gradient-to-r from-gray-900/40 to-gray-800;
      }
    }

    [data-sr-quiz-question-choice=''] {
      input {
        @apply accent-cyan-500;
      }
      label {
        @apply cursor-pointer border-0 text-base;
      }
    }

    [data-sr-quiz-question-choice='correct'] {
      label {
        @apply border-green-500 border-opacity-40;
      }
      span {
        @apply bg-green-500 text-white;
      }
    }

    [data-sr-quiz-question-choice='incorrect'] {
      label {
        @apply cursor-default border-red-500 border-opacity-40;
      }
      span {
        @apply bg-red-500 text-white;
      }
    }

    /* ——— TEXTAREA ——— */
    [data-sr-quiz-question-input] {
      label {
        @apply sr-only;
      }
      textarea {
        @apply flex w-full bg-black/40 p-3;
      }
    }

    /* ——— ANSWER ——— */
    [data-sr-quiz-question-answer] {
      @apply prose max-w-none py-5;
      pre {
        @apply mb-5 p-0;
      }
    }

    /* ——— FOOTER ——— */
    [data-sr-quiz-question-footer] {
      @apply w-full px-5 py-4 text-center text-2xl font-semibold;
    }

    /* —— SUBMIT —— */
    [data-sr-button] {
      @apply inline-flex cursor-pointer items-center rounded-md border-none bg-cyan-400 px-4 py-2 font-medium leading-6 text-black transition hover:bg-cyan-300;
      svg {
        @apply h-6 w-6;
      }
    }
    [data-sr-quiz-question-submit] {
      @apply flex w-full justify-end border-t border-gray-700/50 p-3;
    }

    [data-sr-button][disabled] {
      @apply cursor-not-allowed;
    }
  }
}
