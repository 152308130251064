[data-lesson-description] {
  [data-content] {
    @apply prose relative max-w-none pt-5 sm:prose-lg prose-headings:font-semibold prose-headings:text-gray-100 prose-p:text-gray-300 xl:pt-8 2xl:pt-5;
  }
  [data-content-visible='false'] {
    @apply after:absolute after:bottom-0 after:left-0 after:h-1/2 after:w-full after:bg-gradient-to-b after:from-transparent after:via-gray-900 after:to-gray-900 after:content-[""] sm:after:via-[#0C1222] sm:after:to-[#0C1222] 2xl:after:via-gray-900/80 2xl:after:to-gray-900;
  }
  div[role='status'] {
    @apply flex w-full -translate-y-16 animate-pulse flex-col gap-3;
    div:nth-child(1) {
      @apply h-3 w-2/3 rounded-full bg-gray-700;
    }
    div:nth-child(2) {
      @apply h-3 rounded-full bg-gray-700;
    }
    div:nth-child(3) {
      @apply h-3 w-1/2 rounded-full bg-gray-700;
    }
    div:nth-child(4) {
      @apply h-3 w-5/6 rounded-full bg-gray-700;
    }
    div:nth-child(5) {
      @apply h-3 w-2/5 rounded-full bg-gray-700;
    }
    div:nth-child(6) {
      @apply h-3 w-1/3 rounded-full bg-gray-700;
    }
  }
  [data-cta] {
    @apply relative flex -translate-y-8 items-center justify-center rounded border border-gray-700/50 bg-gray-800 p-5 shadow-2xl before:absolute before:top-[-8px] before:h-4 before:w-4 before:rotate-45 before:border-l before:border-t before:border-gray-700/50 before:bg-gray-800 before:content-[''];
    p {
      @apply prose max-w-none text-gray-200 sm:prose-lg prose-a:text-cyan-300 hover:prose-a:text-cyan-200;
    }
  }
}
