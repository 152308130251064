#__next {
  /* tier name */

  [data-pricing-container] {
    @apply flex gap-10 pt-32 sm:pt-40;
  }

  #team-upgrade-pricing-inline {
    [data-price-container] {
      @apply flex h-14 items-baseline text-2xl font-semibold;

      sup {
        @apply -translate-y-2 pr-0.5 text-xs font-extrabold opacity-80;
      }

      [data-price] {
        @apply flex;
      }

      [data-price-discounted] {
        @apply flex flex-col items-start pl-2;

        [data-full-price] {
          @apply relative flex items-center justify-center text-2xl font-semibold text-gray-300 before:absolute before:h-[3px] before:w-full before:-rotate-12 before:scale-110 before:bg-gray-100 before:opacity-90 before:content-[''];
        }

        [data-percent-off] {
          @apply rounded bg-[#FECD60] px-1.5 text-center font-sans text-xs font-bold uppercase tabular-nums text-gray-900;
        }
      }
    }
  }

  #main-pricing {
    [data-pricing-product='1'] {
      article {
        [data-pricing-product-header] {
          @apply pt-20;
        }
      }

      [data-pricing-product-checkout-button] {
        @apply from-green-300 to-green-500 text-green-950 hover:shadow-green-700/20 !important;
      }
    }
    [data-pricing-product] {
      [data-pricing-product-checkout-button='typescript-pro-essentials'] {
        @apply from-gray-500 to-gray-700 text-gray-950 brightness-125 hover:shadow-gray-700/20 hover:brightness-150 !important;
      }
    }

    [data-pricing-product] {
      @apply relative flex w-full max-w-[406px] flex-col items-center justify-center rounded-md border bg-card shadow-2xl;

      [data-pricing-product-image] {
        @apply pointer-events-none absolute top-[-100px] z-0 h-full max-h-[250px] w-full max-w-[250px] select-none;
      }

      article {
        @apply relative z-10 pt-16 text-base;
        [data-pricing-footer] {
          [data-guarantee-image] {
            @apply hidden;
          }
        }
      }

      [data-pricing-product-header] {
        @apply flex flex-col items-center pt-24;

        [data-title] {
          @apply w-full text-center text-xl font-medium text-gray-200;
        }

        [data-byline] {
          @apply pt-2 text-base font-medium text-gray-200;
        }
        [data-byline]:last-of-type {
          @apply pt-1 text-lg font-medium text-gray-200;
        }

        [data-name-badge] {
          @apply hidden;
        }

        [data-price-container] {
          @apply mt-4 flex h-14 items-baseline text-5xl font-semibold;

          sup {
            @apply -translate-y-4 pr-0.5 text-lg font-extrabold opacity-80;
          }

          [data-price] {
            @apply flex text-white;
          }

          [data-price-discounted] {
            @apply flex flex-col items-start pl-2;

            [data-full-price] {
              @apply relative flex items-center justify-center text-3xl font-semibold text-gray-300 before:absolute before:h-[3px] before:w-full before:-rotate-12 before:scale-110 before:bg-gray-100 before:opacity-90 before:content-[''];
            }

            [data-percent-off] {
              @apply rounded bg-[#FECD60] px-1.5 text-center font-sans text-sm font-bold uppercase tabular-nums text-gray-900;
            }
          }
        }

        [data-price-container='loading'] {
          @apply flex h-[88px] items-center justify-center;
        }
      }

      [data-purchase-container] {
        @apply mb-6;
        [data-guarantee] {
          @apply block pt-3 text-center text-sm text-gray-200;
        }
      }

      [data-purchased-container] {
        @apply w-full overflow-hidden;

        [data-buy-more-seats] {
          @apply mt-5 flex w-full flex-col items-center justify-center py-2 transition;
          button {
            @apply rounded-md bg-white/5 px-5 py-3 text-cyan-200 transition hover:bg-white/10;
          }
        }

        [data-buy-more-seats='active'] {
          @apply bg-white/5;
          button {
            @apply bg-transparent hover:bg-transparent;
          }
        }

        [data-buy-more-seats-form] {
          @apply px-0 pb-5 pt-0;
          #team-upgrade-pricing-inline {
            @apply flex w-full flex-col justify-center;
            [data-pricing-product] {
              @apply w-full border-none bg-transparent shadow-none;
              [data-pricing-product-header] {
                @apply w-full pt-0;
                [data-price-container] {
                  @apply w-full;
                }
                [data-price-container='loading'] {
                  @apply flex h-[56px] items-center justify-center !important;
                }
                button[type='submit'] {
                  @apply w-full border-2 border-cyan-300 bg-transparent py-4 text-lg text-cyan-300 no-underline hover:bg-cyan-300 hover:text-black;
                }
              }
            }
          }
        }

        [data-purchased] {
          @apply mt-5 flex w-full items-center justify-center gap-1 px-5 text-center text-xl font-medium text-white after:hidden;

          svg {
            @apply mt-0.5 h-6 w-6;
          }
        }

        [data-unavailable] {
          @apply my-8 flex w-full items-center justify-center gap-1 rounded-md bg-gray-700 px-5 py-5 text-center text-lg font-semibold text-white shadow-inner after:hidden;

          svg {
            @apply mt-0.5 h-6 w-6;
          }
        }
      }

      [data-downgrade-container] {
        @apply w-full px-8;

        [data-downgrade] {
          @apply my-8 flex w-full items-center justify-center gap-1 rounded-md border-2 border-gray-100 px-5 py-5 text-center text-lg font-semibold after:hidden;
        }
      }

      form {
        @apply flex w-full flex-col items-center justify-center px-5 pt-8 xl:px-5;

        fieldset {
          @apply w-full;
        }

        [data-guarantee] {
          @apply hidden;
        }

        [data-team-switch] {
          @apply flex items-center justify-center gap-2 pb-5;
          label {
            @apply sr-only;
          }
          button[role='button'] {
            @apply text-gray-200 decoration-gray-600 underline-offset-2 transition hover:text-white hover:underline;
          }
          button[role='switch'] {
            @apply relative h-6 w-[47px] rounded-full border border-gray-700/50 bg-gray-800 shadow-md shadow-black/30 transition hover:bg-gray-700/70 radix-state-checked:bg-gray-800 hover:radix-state-checked:bg-gray-700/70;
            span {
              @apply block h-[18px] w-[18px] translate-x-[2px] rounded-full bg-gray-300 shadow-sm shadow-black/30 transition-all will-change-transform group-hover:bg-gray-300 radix-state-checked:translate-x-[25px] radix-state-checked:bg-cyan-400 group-hover:radix-state-checked:bg-cyan-300;
            }
          }
        }

        [data-quantity-input] {
          @apply mb-5 flex w-full flex-col items-center justify-center px-5 xl:px-12;

          div {
            @apply flex items-center gap-1;

            label {
              @apply mr-3 opacity-80;
            }

            input {
              @apply max-w-[70px] rounded-md bg-gray-800 py-2 pl-3 font-mono font-bold;
            }
            button {
              @apply flex h-full items-center justify-center rounded bg-gray-700/50 px-3 py-2 font-mono sm:hidden;
            }
          }
        }
      }

      [data-pricing-product-checkout-button] {
        @apply flex w-full items-center justify-center rounded-md bg-gradient-to-b from-green-300 to-green-600 px-5 py-4 text-center text-lg font-semibold text-gray-950 brightness-105 transition ease-in-out hover:scale-105 hover:shadow-xl hover:shadow-green-700/40 hover:brightness-110 focus-visible:ring-white disabled:cursor-wait;

        span {
          @apply relative z-10;
        }
      }

      [data-pricing-product-checkout-button='typescript-pro-essentials'] {
        @apply bg-gradient-to-b from-emerald-600 to-emerald-800  text-gray-950  hover:shadow-emerald-950/30;
      }

      [data-pricing-product-checkout-button]:disabled {
        @apply cursor-wait;
      }

      [data-pricing-footer] {
        [data-header] {
          @apply w-full pt-8;

          div {
            @apply relative flex items-center justify-center before:absolute before:left-0 before:h-px before:w-full before:bg-gray-800 before:content-[''];

            span {
              @apply relative rounded bg-gray-900 px-4 py-0.5 text-xs font-medium uppercase text-gray-300;
            }
          }
        }
        [data-guarantee-image] {
          @apply flex justify-center pt-8 align-middle;
        }
        [data-main] {
          @apply flex w-full flex-1 flex-col justify-between space-y-6 p-3 px-6 pb-8 pt-6 sm:p-5 sm:pt-6 xl:p-8;

          strong {
            @apply inline-flex font-medium;
          }

          [data-workshops] {
            [data-description] > p {
              @apply hidden text-balance pt-1 text-xs font-normal text-foreground;
            }
            strong {
              @apply pb-4;
            }
            ul {
              @apply space-y-2;
            }
            a {
              @apply hover:underline;
            }
            li,
            a {
              @apply flex items-center;

              [data-image] {
                @apply relative flex h-[100px] w-[100px] flex-shrink-0 items-center justify-center;
              }

              p {
                @apply ml-3 text-base font-semibold text-gray-200 sm:text-lg sm:leading-tight;
              }

              [data-state] {
                @apply ml-3 flex;
              }

              [data-state='draft'] {
                @apply text-cyan-400/80;
              }

              [data-state='published'] {
                @apply hidden;
              }
            }
          }
          [data-bonuses] {
            strong {
              @apply pb-1 text-sm font-semibold uppercase text-yellow-200 before:pr-1 before:content-['🎁'];
            }
            ul {
              @apply space-y-2;
            }
            a {
              @apply hover:underline;
            }
            [data-description] > p {
              @apply text-sm font-normal text-foreground;
            }
            li,
            a {
              @apply flex items-center;

              [data-image] {
                @apply relative flex h-[100px] w-[100px] flex-shrink-0 items-center justify-center;
              }

              p {
                @apply ml-3 inline-flex flex-col text-base font-semibold text-gray-200 sm:text-lg sm:leading-tight;
              }

              [data-state] {
                @apply ml-3 flex;
              }

              [data-state='draft'] {
                @apply text-cyan-400/80;
              }

              [data-state='published'] {
                @apply hidden;
              }
            }
          }
          [data-features] {
            strong {
              @apply flex pb-2;
            }
            li {
              @apply flex items-start py-1 before:pr-2 before:font-bold before:text-cyan-500 before:content-['✓'];
            }
            p {
              @apply text-base text-gray-100;
            }
          }
        }
      }
      /* PPP box */
      [data-ppp-container] {
        @apply mx-auto mb-5 w-full border-y border-gray-800 bg-gray-800/50 p-4 shadow-xl sm:p-5;

        [data-ppp-header] {
          @apply w-full space-y-4;

          strong {
            @apply font-medium;

            img {
              @apply inline-block;
            }
          }
          p {
          }
        }
        label {
          @apply mt-5 flex cursor-pointer gap-2 rounded-md border border-gray-700 bg-gray-700/20 p-3 font-medium tabular-nums accent-cyan-400 transition hover:bg-gray-700/60;

          input {
          }

          span {
            @apply leading-tight;
          }
        }
      }
      /* Countdown box */
      [data-pricing-product-sale-countdown] {
        [data-grid] {
          @apply mx-auto grid max-w-[300px] grid-cols-4 items-center justify-center gap-3 pb-6 tabular-nums tracking-tight;
        }
      }
    }
  }
  /* WORKSHOP TEMPLATE PRICING WIDGET */
  [data-workshop-template] {
    #main-pricing {
      @apply mb-8;
      [data-pricing-product] {
        @apply mx-auto w-full;
        [data-pricing-product-image] {
          @apply mt-20 w-64;
        }
        article {
          @apply w-full pt-40;
          [data-pricing-product-header] {
            @apply pt-12;
            [data-name-badge] {
              @apply block;
            }
          }
          [data-purchase-container] {
          }
          [data-ppp-container] {
            /* @apply mb-0; */
          }
          [data-pricing-footer] {
            div:first-of-type {
              /* @apply hidden; */
            }
            [data-guarantee-image] {
              @apply mx-auto w-28 pb-8;
            }
            [data-header] {
              /* @apply hidden; */
            }
            [data-main] {
              /* @apply hidden; */
              div > strong {
                @apply block;
              }
              p > strong {
                @apply block;
              }
              strong {
                @apply hidden;
              }
              [data-features] {
                @apply hidden;
              }
            }
          }
        }
      }
    }
  }

  #pricing {
    [data-sr-convertkit-subscribe-form] {
      @apply flex w-full flex-col gap-3 px-0 pt-5 text-left;

      [data-sr-input-wrapper] {
        @apply w-full;
      }

      [data-sr-input] {
        @apply w-full rounded-md border border-gray-800 bg-gray-800/50 px-3 py-3 text-base;
      }

      [data-sr-input-label] {
        @apply sr-only;
      }

      [data-sr-button] {
        @apply relative flex w-full flex-shrink-0 items-center justify-center rounded-md bg-gradient-to-b from-cyan-300 to-cyan-400 px-5 py-3 text-lg font-semibold text-black shadow-2xl shadow-cyan-900/50 transition hover:brightness-110 focus-visible:ring-white;

        svg {
          @apply h-7 w-7;
        }
      }
      [data-sr-button][disabled] {
      }
    }
    [data-sr-convertkit-subscribe-form='success'] {
      p {
        @apply py-2 text-center;
      }
    }
    [data-sr-convertkit-subscribe-form='error'] {
      p {
        @apply py-2 text-center;
      }
    }
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -0.3em;
    vertical-align: super;
  }

  sub {
    bottom: -0.25em;
    vertical-align: sub;
  }

  #buy-more-seats {
    #team-upgrade-pricing-inline {
      @apply flex flex-col sm:flex-row;
    }
    [data-pricing-product-header] {
      @apply flex flex-col pt-5 sm:flex-row sm:pt-0;
    }
    [data-loading-price] {
      @apply flex h-full w-full items-center justify-center;
    }
    [data-price] {
      @apply items-center text-4xl;
      span {
        @apply self-start;
      }
    }
    button[type='submit'] {
      @apply px-8 font-semibold;
    }
  }

  #video-overlay {
    #main-pricing {
      @apply flex w-full items-center justify-center;
    }
    [data-pricing-product] {
      @apply relative border-none bg-transparent shadow-none;

      [data-pricing-product-image] {
        @apply hidden;
      }
      article {
        @apply static pt-0;
      }
      [data-pricing-product-header] {
        @apply pt-0;
        [data-price-container] {
          @apply mt-1;
        }
      }
      [data-team-switch] {
        @apply text-base;
      }

      fieldset,
      article,
      form,
      [data-purchase-container],
      [data-pricing-product-checkout-button] {
        @apply w-full;
      }
      [data-purchase-container] {
        @apply mb-3;
      }
      form {
        @apply px-0 pt-5;
      }
      [data-pricing-product-checkout-button] {
        @apply sm:px-10;
      }
      [data-purchased-container] {
        div {
          @apply leading-relaxed;
        }
        #pricing > div {
          @apply text-left leading-normal;
        }
        [data-unavailable] {
          @apply mt-0 bg-transparent pb-0 pt-0 font-text text-4xl font-extrabold text-cyan-200;
        }
      }
      [data-pricing-footer] {
        @apply hidden;
      }
      [data-pricing-product-sale-countdown] {
        @apply px-0 pb-3;

        div[class='w-full rounded-lg text-center'] {
          @apply flex flex-row;
        }
        p {
          @apply flex items-center pb-0 text-left text-sm;
        }
        div[aria-hidden='true'] {
          @apply mx-0 gap-2;
        }
        span:first-of-type {
          @apply text-2xl;
        }
        span:last-of-type {
          @apply pt-0 text-xs text-white/80;
        }
      }
      [data-ppp-container] {
        @apply rounded-lg border border-gray-800 bg-black/60 p-3;
        [data-ppp-header] {
          @apply text-left text-base lg:text-sm;
          p {
            @apply lg:inline lg:before:content-["_"];
          }
        }
        label {
          @apply mt-2 text-sm;
          input {
          }
          span {
          }
        }
      }
    }
  }

  [data-product-page] {
    [data-price-container] {
      @apply mt-0 flex w-28 items-start justify-center;
      svg {
        @apply w-4;
      }
      sup {
        @apply mt-1.5 transform-none text-sm font-medium text-gray-300;
      }
      [data-price] {
        @apply flex items-start text-3xl font-semibold;
        span {
          @apply font-normal text-gray-300;
        }
      }
      [data-full-price] {
        @apply hidden;
      }
      [data-percent-off] {
        @apply hidden;
      }
    }
  }

  #products-index-page {
    [data-price-container] {
      @apply mt-0 flex items-start;

      sup {
        @apply mt-1.5 transform-none text-sm font-medium text-gray-400;
      }
      [data-price] {
        @apply flex text-3xl font-medium;
        span {
          @apply text-gray-400;
        }
      }
      [data-price-discounted] {
        @apply flex items-center gap-1;
      }
      [data-full-price] {
        @apply pl-2 text-2xl text-gray-400 line-through;
      }
      [data-percent-off] {
        @apply pl-1 text-base text-cyan-300;
      }
    }
    [data-pricing-product] {
      @apply relative flex items-start justify-start border-none bg-transparent pt-0 shadow-none;
      article {
        @apply pt-0;
        [data-pricing-product-header] {
          @apply flex flex-row items-start gap-3 pt-0;
          [data-title] {
            @apply hidden;
          }
          [data-name-badge] {
            @apply hidden;
          }
          [data-price-container] {
            @apply mt-0 flex items-start;
            sup {
              @apply mt-1.5 transform-none text-sm font-medium;
            }
            [data-price] {
              @apply text-3xl;
            }
          }
        }
        [data-purchase-container] {
          @apply hidden;
        }
        [data-ppp-container] {
          @apply hidden;
        }
        [data-pricing-footer] {
        }
      }
    }
  }
}
