@tailwind base;

@layer base {
  :root {
    color-scheme: light;
    ::selection {
    }

    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    color-scheme: dark;
    ::selection {
      @apply bg-cyan-300 text-gray-900;
    }

    --gray-950: 217 45% 6%;
    --gray-900: 219 44% 8%;

    --background: 220 43% 10%;
    --foreground: 214 43% 83%;

    --muted: 210 58% 84%;
    --muted-foreground: 220 20% 60%;

    --popover: 220 43% 10%;
    --popover-foreground: 214 43% 83%;

    --border: 219 35% 19%;
    --input: 220 43% 10%;

    --card: 220 36% 12%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 177 87% 75%;
    --primary-foreground: 188 41.7% 10.8%;

    --secondary: 210 35% 19%;
    --secondary-foreground: 214 43% 83%;

    --accent: 219 35% 19%;
    --accent-foreground: 214 43% 83%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 186.9 92.3% 69%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    h1,
    h2,
    h3,
    h4 {
      @apply text-white;
    }
    font-feature-settings: 'rlig' 1, 'calt' 1;
  }
}

@tailwind components;
@import '@code-hike/mdx/dist/index.css';
@import './survey.css';
@import './newsletter.css';
@import './nprogress.css';
@import './prism-theme.css';
@import './pricing.css';
@import './purchase-transfer.css';
@import './portable-text.css';
@import './team.css';
@import './redeem-dialog.css';
@import './login.css';

@import './video/github-link.css';
@import './video/lesson-completion-toggle.css';
@import './video/lesson-description.css';
@import './video/lesson-title.css';
@import './video/module-lesson-list.css';
@import './video/video-overlays.css';
@import './video/video-transcript.css';
@import './video/reset-progress.css';

@import '../search-bar/cmdk.css';

/* NOTE: 
shiki-twoslash styles are imported on page level
since they tend to clash with prism-theme.css */
/* @import './shiki-twoslash.css'; */

@tailwind utilities;
@import './focus-visible.css';

/* book shiki code blocks */

.dark-plus {
  @apply rounded border border-gray-100 bg-background dark:border-0 dark:bg-white/5 !important;
}

/* code-hike code blocks */
.ch-code {
  @apply rounded border border-gray-100 bg-background dark:border-0 dark:bg-white/5 !important;
}

.ch-codeblock {
  @apply rounded shadow-none;
}

.ch-code-scroll-parent {
  @apply scrollbar-thin scrollbar-track-slate-700 scrollbar-thumb-slate-500/75;
}

body {
  @apply overflow-y-scroll;
}

mux-player {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
  /* margin-bottom: -6px; */
  @apply overflow-hidden;
}

mux-player::part(center play button) {
  @apply sm:scale-50;
}

mux-player::part(vertical-layer) {
  background-color: rgba(0, 0, 0, 0);
}

[data-body-video] {
  @apply overflow-hidden rounded-md;
}

code {
  @apply rounded-md bg-gray-800/90 px-1.5 py-0.5 text-[85%];
}

.no-marker {
  ::-webkit-details-marker {
    display: none;
  }
}

.simplebar-scrollbar:before {
  @apply bg-white/50;
}

.not-prose {
  code {
    @apply bg-transparent;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  @apply opacity-100;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
