#primary-newsletter-cta {
  [data-sr-convertkit-subscribe-form] {
    @apply flex flex-col gap-4;
    [data-sr-input] {
      @apply w-full rounded-sm border border-gray-800 bg-gray-800/50 p-5 text-lg;
    }
    [data-sr-input-label] {
      @apply inline-block w-full pb-1 text-gray-300;
    }
    [data-sr-button] {
      @apply relative mt-2 flex items-center justify-center rounded bg-primary px-8 py-5 text-xl font-semibold text-primary-foreground shadow-2xl shadow-cyan-900/50 transition hover:brightness-110 focus-visible:ring-white;
      svg {
        @apply h-7 w-7;
      }
    }
    [data-sr-button][disabled] {
    }
  }
  [data-sr-convertkit-subscribe-form='success'] {
  }
  [data-sr-convertkit-subscribe-form='error'] {
  }
}

#video-overlay {
  [data-sr-convertkit-subscribe-form] {
    @apply flex w-full max-w-sm flex-col gap-4 text-left;
    [data-sr-input] {
      @apply w-full rounded-md border border-gray-800 bg-gray-900 px-3 py-3 text-lg;
    }
    [data-sr-input-label] {
      @apply inline-block w-full pb-1 text-gray-300;
    }
    [data-sr-button] {
      @apply relative mt-2 flex items-center justify-center rounded-md bg-gradient-to-tr from-cyan-500 to-cyan-400 px-5 py-4 text-lg font-semibold text-black shadow-2xl shadow-cyan-900/50 transition hover:brightness-110 focus-visible:ring-white;
      svg {
        @apply h-7 w-7;
      }
    }
    [data-sr-button][disabled] {
    }
  }
  [data-sr-convertkit-subscribe-form='success'] {
    p {
      @apply py-2 text-center;
    }
  }
  [data-sr-convertkit-subscribe-form='error'] {
    p {
      @apply py-2 text-center;
    }
  }
}

#tip {
  [data-sr-convertkit-subscribe-form] {
    @apply flex w-full flex-col gap-2 text-left md:flex-row;
    [data-sr-input-wrapper] {
      @apply w-full;
    }
    [data-sr-input-wrapper]:first-of-type {
      @apply md:w-2/3;
    }
    [data-sr-input] {
      @apply w-full rounded-md border border-gray-800 bg-gray-800/50 px-3 py-2 text-lg;
    }

    [data-sr-input-label] {
      @apply sr-only;
    }
    [data-sr-button] {
      @apply relative flex flex-shrink-0 items-center justify-center rounded-md bg-gradient-to-tr from-cyan-600 to-cyan-500 px-5 py-2 text-lg font-semibold shadow-2xl shadow-cyan-900/50 transition hover:brightness-110 focus-visible:ring-white;
      svg {
        @apply h-7 w-7;
      }
    }
    [data-sr-button][disabled] {
    }
  }
  [data-sr-convertkit-subscribe-form='success'] {
    p {
      @apply py-2 text-center;
    }
  }
  [data-sr-convertkit-subscribe-form='error'] {
    p {
      @apply py-2 text-center;
    }
  }
}

#article-cta {
  [data-sr-convertkit-subscribe-form] {
    @apply relative z-10 mx-auto flex w-full max-w-2xl flex-col items-center gap-2 text-left md:flex-row md:items-end;
    [data-sr-input-wrapper] {
      @apply w-full;
    }
    [data-sr-input-wrapper]:first-of-type {
    }
    [data-sr-input] {
      @apply w-full rounded-md border border-gray-700/50 bg-gray-800/50 px-3 py-2 text-lg;
    }
    [data-sr-input-label] {
      @apply block pb-1 text-gray-300;
    }
    abbr {
      @apply inline-block text-gray-400 decoration-transparent;
    }
    [data-sr-button] {
      @apply relative mt-5 flex flex-shrink-0 items-center justify-center rounded bg-primary px-5 py-2 text-lg font-semibold text-black text-primary-foreground shadow-2xl shadow-black/80 transition hover:brightness-125 focus-visible:ring-white md:mt-0;
      svg {
        @apply h-7 w-7;
      }
    }
    [data-sr-button][disabled] {
    }
  }
  [data-sr-convertkit-subscribe-form='success'] {
    p {
      @apply py-2 text-center;
    }
  }
  [data-sr-convertkit-subscribe-form='error'] {
    p {
      @apply py-2 text-center;
    }
  }
}
